import React from 'react';

import LayoutBlog from '../components/LayoutBlog';
import Footer from '../components/Footer';
import Header from '../components/Header';
import LogoFull from '../components/LogoFull';
import EmailForm from '../components/EmailForm';
import EmailFormInPage from "../components/EmailFormInPage";
import BackToHomeArrow from '../components/BackToHomeArrow';
import ShareLinks from '../components/ShareLinks';
import PopUpOnExitEmailForm from '../components/PopUpOnExitEmailForm';

const BlogMain = () => (
  <LayoutBlog title={"Crafted #001 - George Duncan, Duncan's Brewing"}
              blogUrl={"https://joincrafted.com/001-duncans-brewing"}
              description={"In this edition, we get insight from George Duncan, Head Brewer & Owner at Duncan's Brewing—a microbrewery loved by its community in New Paraparaumu, New Zealand."}
              imageUrl={"https://www.joincrafted.com/assets/images/adam-wilson-ANK5zq-g_-g-unsplash_resized.jpg"} >
    <Header />
      <div>
      <main className="blog-main" role="main">
      <div className="blog-left">
        <ShareLinks
          twitterLink={"https://twitter.com/intent/tweet?text=Learn%20beer%20from%20brewmasters.%20In%20this%20edition,%20we%20get%20insight%20from%20George%20Duncan,%20Head%20Brewer%20%26%20Owner%20at%20Duncan's%20Brewing%E2%80%94a%20microbrewery%20loved%20by%20its%20community%20in%20New%20Paraparaumu,%20New%20Zealand.%0Ahttps%3A//joincrafted.com/001-duncans-brewing"}
          facebookLink={"https://www.facebook.com/sharer/sharer.php?u=https://joincrafted.com/001-duncans-brewing"} />
      </div>
      <div className="slim-container">
        <div className="mt-4 mx-2 d-flex justify-content-between">
          <div>
            <BackToHomeArrow />
          </div>
          <div style={{color: 'rgba(40, 50, 78, 0.5)', fontSize: '16px', lineHeight: '1.5'}}>
            <p>August 31, 2020
            </p>
          </div>
        </div>
        <div className="100vw d-flex justify-content-center mt-4">
          <LogoFull></LogoFull>
        </div>
        <table className="document" align="center">
          <tbody>
            <tr>
              <td valign="top">
                {/* Main */}
                <table cellSpacing={0} cellPadding={0} border={0} align="center" width={630} className="container">
                  <tbody>
                    <tr className="inner-body">
                      <td>
                        <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                          {/* Header!*/}
                          <tbody>
                            {/*Main body*/}
                            <tr bgcolor="#ffffff" style={{backgroundColor: '#ffffff'}}>
                              <td className="container">
                                <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                                  <tbody>
                                    <tr>
                                      <td style={{padding: '5px 5px'}} >
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{textAlign: 'center', marginBottom: '2em', color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                  <h1 style={{color: "rgba(40, 50, 78, 0.5)", fontWeight: '400', fontSize: '16px', lineHeight: '1.5'}}><span style={{display: 'none'}}>Crafted </span>Edition &#35;001 🍻 - George Duncan, Duncan's Brewing</h1>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                  <p>Our goal is to take you behind the scenes of the craft beer industry, to explore the daily routines and philosophies of brewers and brewery owners. Our hope is that this insider-knowledge will make that next beer of yours taste a little better 🍻
                                                  </p>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#3D405B', fontSize: '16px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                  In this edition we have the honor of gleaning insights from <strong>George Duncan, Head Brewer &amp; Owner</strong> at <a href="https://www.duncansbrewing.co.nz/" style={{color: '#6821AB', textDecoration: 'underline'}} rel="noreferrer" target="_blank">Duncan's Brewing</a>—a microbrewery loved by its community in New Paraparaumu, New Zealand. <a href="https://untappd.com/Duncansbrewingnz" style={{color: '#6821AB', textDecoration: 'underline'}} rel="noreferrer" target="_blank">Check it out on Untappd.</a>
                                                  <br />
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                  <p>If you're pressed for time, here's some brief takeaways:
                                                  </p>
                                                  <ul>
                                                    <li>Last year's fad is this year's every-day</li>
                                                    <li>Consider beer tourism to New Zealand 🇳🇿 (after Coronavirus ✈️)</li>
                                                    <li>Sell experience, not just beer</li>
                                                  </ul>
                                                  <p style={{paddingTop: '16px'}}>Let's get to it 🚀</p>
                                                  <EmailFormInPage />
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%" >
                                          <tbody>
                                            <tr>
                                              <td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                                                <div className="text-block" style={{lineHeight: '150%'}}>
                                                  <span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}> </span></span>
                                                  <div style={{textAlign: 'center'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></span></div>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#DC8718', fontSize: '16px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#FDF6ED', padding: '8px', borderRadius: '4px'}}><span className="fr-unprocessed">
                                                    <strong>A quick note:</strong>
                                                    <br />
                                                    George packed so much value into our exchanges. I just couldn't fit it all into this one letter. So, necessarily, what follows are condensed highlights of our interactions. Hopefully one day I can release the full-length version.
                                                  </span>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                  <strong>How'd you get into brewing? What was your journey like from beer drinker, to brewer, to owner?</strong>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                  <p style={{paddingBottom: '16px'}}>The mainspark for me with brewing was when I was studying at university. I took a Chem paper and needed to be tutored. So a friend who was a biochemist helped me out. It turns out he was great brewer. It blew my mind that you could make amazing beer at home and I was hooked.
                                                  </p>
                                                  <p style={{paddingBottom: '16px'}}>It wasn't until about 8 years later after me and my wife came home from our <a href="https://en.wikipedia.org/wiki/Overseas_experience" rel="noreferrer" target="_blank" style={{color: '#6821AB', textDecoration: 'underline'}}>Overseas Experience</a> in north America that I started Duncan's. In the beginning it was just a hobby business, contract brewing where i could find space until three years ago we got the opportunity to open our own physical brewery.</p>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}>
                                                    <strong>What is the beer that you are most proud of?</strong>
                                                  </span>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                  <p style={{paddingBottom: '16px'}}>The beer I'm most proud of is our <a href="https://untappd.com/b/duncan-s-brewing-pale-ale/776994" rel="noreferrer" target="_blank" style={{color: '#6821AB', textDecoration: 'underline'}}>Pale Ale (5.7% ABV, NZ &amp; US hops)</a>, it was the first beer I ever brewed. In its current form its tastes nothing like the original recipe. To me, I can taste the journey this beer has gone on as I honed my skills as a brewer.
                                                  </p>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}} >
                                          <tbody>
                                            <tr>
                                              <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top"><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}> <img src="https://gallery.eomail4.com/5d6ee9b8-e53b-11ea-a3d0-06b4694bee2a%2F1598606304958-duncans_pale_ale.jpg" width={167} style={{width: '27%', maxWidth: '100%', objectFit: 'cover', padding: '0px', outline: '0px', border: 'none', display: 'block'}} /> </span></td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}>
                                                    <strong>What would today's George have to say to George from the past as he was brewing that first version of the pale ale?</strong>
                                                  </span>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                  <p style={{paddingBottom: '16px'}}>Don't put crystal malt in. 😁</p>
                                                  <p style={{paddingBottom: '16px'}}>
                                                    Its the death of every beer. That's just a philosophy I have in the brewery - we don't use <a href="https://beerandbrewing.com/dictionary/Sdt5aAdUAi/" rel="noreferrer" target="_blank" style={{color: '#6821AB', textDecoration: 'underline'}}>crystal malt</a> in anything that's not a stout. That's the major change. I was drinking Sierra Nevada Pale Ale, Epic Pale Ale—which is another New Zealand one—and they're all real crystal-heavy and they just don't age well. They get that real marmalade-y, oxidized flavor.
                                                  </p>
                                                  <p style={{paddingBottom: '16px'}}>
                                                    A brewer friend of mine about 3 years ago told me about crystal malt and how it can contribute to that [flavor]. Then one day I was brewing a batch of pale ale and I didn't order the crystal malt, so I threw in a bunch of Munich to get the color change and it was such a better beer. But I what mean by saying I can taste it [the evolution] - even the hops that we used back then - I was using more traditional hops...And now its Motueka™, Mosaic ® and US Cascade - its a way more modern beer.
                                                  </p>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}>
                                                    <strong>What's one thing that aspiring brewers should know about the job, something you wish you'd known?</strong>
                                                  </span>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                  <p style={{paddingBottom: '16px'}}>
                                                    So I don't actually brew beer much any more. I brewed for about two years then employed a brewer. I enjoy running a brewery much more than mashing-in everyday. Being a brewer is a production job - you will probably be banging out the same beer week in and week out. If you love fine-tuning recipes and really crafting the beer down to its fine details, its the job for you.
                                                  </p>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}>
                                                    <strong>What does it mean to run a brewery?</strong>
                                                  </span>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                  <p style={{paddingBottom: '16px'}}>Boiled down, owning or running a brewery is just like owning any business. Manufacturing the products is one key component of it. And what I mean by not doing the brewing myself is that I've got a brewer who works for me, Shane. I oversee all the production, though.</p>
                                                  <p style={{paddingBottom: '16px'}}>
                                                    I do all the recipe development, working with the brewer. I'm still on the floor quite a bit, but I'll be just checking gravities, monitoring ferments, deciding when beer needs to be packaged. And then a lot of our beers we kind of brew on the fly. So we'll do multiple dry hops to get to where we want to get. We're adding fruit, we might add multiple additions to get it where we need it. So I'm kind of the person that has the big say in it.
                                                  </p>
                                                  <EmailFormInPage />
                                                  <p style={{paddingBottom: '16px'}}>
                                                    But what I don't do is mashing, I don't do run-offs, I don't boil, I don't put hops in. I mean, I jump in if I need to. But, in all fairness, when I was doing that stuff the business side of running the brewery was really suffering. I wasn't available to take calls, respond to emails, to pick up all the loose ends. You get to the end of the day, and you've made beer and that's cool. But you can pay someone to make beer. you can't pay someone to make overarching decisions about direction your supposed to be going. That's your responsibility.
                                                  </p>
                                                  <p style={{paddingBottom: '16px'}}>
                                                    Also, I've got a young family. If I want to go and have lunch with the family I just go and do it. But then sometimes I have to go to work when the kids are in bed, until 10 o'clock at night to get things ready for the next day. I think its a good job if you're flexible and if you can deal with the irregularities of the day.
                                                  </p>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}>
                                                    <strong>This is one of those questions that is very cliché, but what is success for Duncan's Brewing 10 years from now?
                                                    </strong>
                                                  </span>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                  <p style={{paddingBottom: '16px'}}>I mean its going to sound even more cliché, but I kind of feel like we're getting real close to it now. </p>
                                                  <p style={{paddingBottom: '16px'}}>
                                                    I'm really enjoying where we're at. I'd like a few more staff - there's some jobs I'd like to move on to other people to get them done right. But in all fairness, I'm really enjoying where we're at right now. We can't make enough beer, there's two of us in the brewery - me and Shane. We just play music all day, have a great time, and make beer, and we sell beer constantly.
                                                  </p>
                                                  <p style={{paddingBottom: '16px'}}>
                                                    I don't know if massive growth is something I'm super in-to. For me, its more about creating fun and exciting beers over growth. And working with distributors and people that really appreciate what we do is key. That's actually a big part. I just got a message from our Australian distributor and he's just so jazzed about the artwork of our newest beer. And he always wants to order way more that we can give him, and for me that's kind of success in its own way isn't it?
                                                  </p>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%" >
                                          <tbody>
                                            <tr>
                                              <td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                                                <div className="text-block" style={{lineHeight: '150%'}}>
                                                  <span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}> </span></span>
                                                  <div style={{textAlign: 'center'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></span></div>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                  <p style={{paddingBottom: '8px'}}>Success is being <strong>good at what you do</strong> and <strong>happy where you are</strong>.</p>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#4cb14c', fontSize: '16px', lineHeight: '1.5', backgroundColor: '#F3F9F0', padding: '8px', borderRadius: '4px'}}>
                                                  There was so much more worth writing. A big <strong>thank you to George</strong> for sharing a bit of his story with us. He's a great guy who is generous with his resources.
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                          <tbody>
                                            <tr>
                                              <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                                <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                  <p style={{paddingBottom: '16px', paddingTop: '8px'}}>And, I want to say <strong>thank you</strong> for reading this far. I appreciate this growing community and really want to deliver quality content. So if you want to share an idea, have questions for our next brewer, or just want to say hi 👋 - all you have to do is reply to this email. 😊</p>
                                                  <p style={{paddingBottom: '16px'}}>Take care, until next time.</p>
                                                  <p style={{paddingBottom: '16px'}}>Cheers! 🍻</p>
                                                  <p style={{paddingBottom: '16px'}}>- Jake</p>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <div className="spacer-div"></div>
      </div>
      <div className="blog-right"></div>
      </main>
      </div>
      <div id="overlay">
        <div id="loader-object"></div>
      </div>
    <Footer />
  </LayoutBlog>
);

export default BlogMain;
